@font-face {
    font-family: 'Sporting-Bold';
    src: local('SportingGrotesque-Bold'),
         url('../fonts/SportingGrotesque-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Clearface-Regular';
    src: local('Clearface-Regular'),
         url('../fonts/Clearface-Regular.woff') format('woff');
  
  }

@font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
         url('../fonts/NeueMontreal-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Voyage';
    src: local('grandefoyage'),
         url('../fonts/grandefoyage.woff') format('woff');
}

.case-header{
    width: 100%;
    margin-top: 0;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: cover;
    background-size: cover;
}

.project-title{
    width: fit-content;
    min-width: 350px;
    min-height: 30%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 5%);
    background-color: rgba(255, 255, 255, 0.009);   
 	backdrop-filter: blur(8px);
    border-radius: 20px;
    gap: 20px;
    padding: 2rem;
    text-align: center;

}
.project-title h1{
    font-family: 'Sporting-bold';
    font-size: 50px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
    
}

.project-title h3{
    font-family: 'NeueMontreal-Medium';
    color: white;
    margin: 0;
}

.case-summary{
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: fit-content 1fr;
    column-gap: 6rem;
    row-gap: 3rem;
    margin-top: -4rem;
}

.case-summary h3{
    color: white;
    font-family: 'Sporting-bold';
    font-size: 28px;
    line-height: 35px;
    animation: .5s ease-out 0s 1 slideInFromLeft;
}

  
.case-summary p{
    color: rgba(255, 255, 255, 0.786);
    font-family: 'NeueMontreal-Medium';
    font-size: 18px;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    animation: .3s ease-out 0s 1 wipeInFromBot forwards;
    animation-delay: .5s;

   
}

.project-link-container:hover{
    transform-origin: center;
    scale: 120%;
    
}


h1 span {
    font-size: 16px;
    font: Monaco, MonoSpace;
    height: 90px;
    position: absolute;
    width: 20px;
    left: 45%;
    top: 0;
    transform-origin: bottom center;
    color: white;
  }

.project-link-container{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    top: 0;
    left: 32%;
    transition: .5s cubic-bezier(0.5,-.3,.265,1);  
    object-fit: cover;
    animation: rotateText 10s linear infinite; 

   
}

.arrow-div{
    width: 180px;
    height: 180px;
    position: absolute;
    scale: 70%;
    top: 0;
    left: 32%;

}

.arrow-div img{
    width: 180px;
    height: 180px;
    scale: 50%;
    position: absolute;
    filter: invert(1);
    z-index: 1;
    opacity: 80%;
}




.project-link{
    width: 100%;
    position: relative;

}


.case-study{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.case-details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

h3{
    margin: 0;
}

.case-details p{
    margin: 0;
}

.case-study-image{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;

}

.case-study-image img{
    height: 100%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    aspect-ratio: 4 / 3;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    animation: .3s ease-out 0s 1 wipeInFromBot forwards;
    animation-delay: .5s;
    background-color: white;
}

.techs{
    display: flex;
    flex-wrap: wrap;
    gap: .7rem;
    width: 70%;
    justify-content: left;
    margin-bottom: 1rem;
}


.tech{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    width: fit-content;
    padding: .8rem;
    padding-inline: 1.2rem;
    border-radius: 30px;
    font-family: 'NeueMontreal-Medium';
    font-size: 14px;
    color: white;
    vertical-align: baseline;
}

.highlight{
    background-color: #fc7a5b;
    border: 2px solid #fc7a5b;
    color: white;
}

.project-gallery{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 185vh;
    padding-bottom: 3rem;
   
}

.gallery1{
    background: white;
    width: 90%;
    height: fit-content;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    display: flex;
    justify-content: center;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    transition: 0.8s cubic-bezier(0.5,-.3,.265,1);
}

.gallery-section-1{
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: white;
    gap: 1rem;
}

.gallery-section-1 h2{
   padding-left: 3rem;
    margin-bottom: 0;
}

.gallery-section-2{
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
    color: white;
    gap: 1rem;
}

.gallery-section-2 h2{
    padding-right: 3rem;
    margin-bottom: 0;
 }

.gallery2{
    background: white;
    width: 90%;
    height: fit-content;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    display: flex;
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    transition: 0.8s cubic-bezier(0.5,-.3,.265,1);
}

.gallery1 img{
    object-fit: contain;
    max-height: 600px;
    justify-content: center;
    animation: .8s cubic-bezier(0.5,-.3,.265,1) slideInFromLeft;
}

.gallery2 img{
    object-fit: contain;
    max-height: 600px;
    justify-content: center;
    animation: .8s cubic-bezier(0.5,-.3,.265,1) slideInFromRight;
}

@media only screen and (max-width: 1366px) {

    .gallery1 img{
        object-fit: contain;
        max-height: 550px;
        min-height: 200px;
      
    }
    
    .gallery2 img{
        object-fit: contain;
        max-height: 550px;
        min-height: 200px;
        
    }


    
}


@keyframes rotateText {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%) skew(-.312rad);
      }
      100% {
        transform: translateX(0) skew(0);
      }
    }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%) skew(.312rad);
    }
    100% {
      transform: translateX(0) skew(0);
    }
  }

  @keyframes wipeInFromBot {
    0% {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0); 
    }
  }
