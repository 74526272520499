@font-face {
    font-family: 'Sporting-Bold';
    src: local('SportingGrotesque-Bold'),
         url('../fonts/SportingGrotesque-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Sporting-Regular';
    src: local('SportingGrotesque-Regular'),
         url('../fonts/SportingGrotesque-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Voyage';
    src: local('grandefoyage'),
         url('../fonts/grandefoyage.woff') format('woff');
}

@font-face {
    font-family: 'Clearface-Regular';
    src: local('Clearface-Regular'),
         url('../fonts/Clearface-Regular.woff') format('woff');
  
  }

  @font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
         url('../fonts/NeueMontreal-Medium.woff') format('woff');
}

.about-me-container{
    height: 100%;
    padding-right: 3rem;

   

}

.about-me-container h1{
    color: white;
    font-size: 50px;
    font-family: 'Sporting-Bold';
}

.about-me-header{
    height: 15%;
    display: flex;
    align-items: center;
}

.about-me-header h1{
    margin: 0;
}

.about-inner-container{
    background-color: white;
    height: fit-content;
    /* border-radius: 60px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 100px;
    background-color: white;
}

.about-left-col{
    position: sticky;
    top: 0;
    background-color: #fc7a5b;
    padding: 3rem;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;

}

.about-left-col h1{
    margin: 0;
    font-family: 'Sporting-Bold';
    font-size: 60px;
}
.about-left-col a{
    margin: 0;
    font-family: 'Sporting-Bold';
    font-size: 28px;
    color: white;
    text-decoration: none;
    width: fit-content;
}

.about-left-col p{
    margin: 0;
    font-family: 'NeueMontreal-Medium';
    font-size: 22.5px;
    text-align: left;
    margin-top: 3rem;
    color: rgba(255, 255, 255, 0.885);
}

.about-right-col{
    padding: 3rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3rem;
    
}

.skills-card{
    background-color: rgb(222, 222, 222);
    height: fit-content;
    min-height: 35rem;
    border-radius: 60px;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    transition: 0.8s cubic-bezier(0.5,-.3,.265,1);
    color: #000000;

}


.skills-card h2 {
    font-family: 'Sporting-Bold';
    margin: 0;
    color: #fc7a5b;
    color: #363636;
}

.skills-card h4 {
    font-family: 'Sporting-Regular';
    font-size: 20px;
    margin: 0;
    color: #232323;
}

.skills-card ul {
    margin: 0;
    font-family: 'NeueMontreal-Medium';
    font-size: 20px;
    list-style: none;
    text-indent: -1em;
}

.skills-card ul li::before{
    content: '// ';
}

.skills{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    width: 100%;
    justify-content: left;
    margin-bottom: 1rem;
}

.skill{
    border: 2px solid #2b2b2b;
    width: fit-content;
    padding: .8rem;
    border-radius: 30px;
    font-family: 'NeueMontreal-Medium';
    font-size: 12px;
}

.highlight{
    background-color: #fc7a5b;
    border: 2px solid #fc7a5b;
    color: white;
}

.skills-card p{
    font-family: 'NeueMontreal-Medium';
    font-size: 25px;
}

.underline-about{
    height: 4px;
    margin-top: -.5rem;
    background-color: white;
    transition: 0.8s cubic-bezier(0.5,-.3,.265,1);
}

@media only screen and (max-width: 1366px) {

    /* Left Col */
    .about-left-col h1{
        font-size: 50px;
    }
    .about-left-col a{
       font-size: 25px;
    }
    .about-left-col p{
        font-size: 20px;
    }

    /* Right Col */
    .skills-card h2 {
        font-size: 40px;
    }


    
}