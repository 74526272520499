body{
  background-color: #1a1a1a;
  padding-left: 80px;
}

.App{
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

*{
  cursor: url('./Images/cursor.png'), auto;
}

a:visited {
  text-decoration: none;
}

.home{
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .6s;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.case-studies-body{
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .6s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


@media only screen and (max-width: 1366px) {
  body{
    padding-left: 60px;
  }

}


@media only screen and (max-width: 400px) {
  body{
    max-width: 100vw;
  }

}