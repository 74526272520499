@font-face {
    font-family: 'Sporting-Bold';
    src: local('SportingGrotesque-Bold'),
         url('../fonts/SportingGrotesque-Bold.woff') format('woff');
}

@font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
         url('../fonts/NeueMontreal-Medium.woff') format('woff');
}


.item{
    max-height: 30rem;
    min-width: 50rem;
    border-radius: 50px;
    overflow: hidden;
    cursor: grab;
}

.project-card>img{
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit:cover;

}

.project-card{
    width: 100%;
    height: 100%;
    cursor: grab;
    position: relative;
}

.project-details{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(110, 110, 110, 0.364);  
 	backdrop-filter: blur(1px);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 10px;
    align-items: left;
    padding-left: 2rem;
    padding-bottom: 2rem;
    padding-top: 25%;
    color: white;
    cursor: grab;
}

.project-details h2{
    margin: 0;
    font-family: 'Sporting-Bold';
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 0;

}

.project-details button{
    width: fit-content;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .6rem;
    padding-bottom: .6rem;
    background-color:#fc7a5b;
    color: white;
    font-family: 'Sporting-Bold';
    border: none;
    border-radius: 25px;
    margin-bottom: 0;
    box-sizing: border-box;
    cursor: pointer;

}
.project-details a{
    font-size: 15px;
    cursor: pointer;
}

.project-details p{
    font-size: 22px;
    margin: 0;
    margin-top: 3px;
}

.project-details a:visited{
    color: white;

}
.inner-carousel{
    display: flex;
    gap: 40px;
}

.carousel{
    cursor: grab;
    width: 40rem;
    height: 100%;

}

.btnarw{
    max-width: 10%;
}

@media only screen and (max-width: 1366px) {

    .item{
        height: 25rem;
        min-width: 40rem;
    }
    .project-details h2{
        font-size: 40px;
    }
    .project-details p{
        font-size: 20px;
    }
    .project-details a{
        font-size: 15px;
        cursor: pointer;
    }
    
    

}