@font-face {
    font-family: 'Sporting-Bold';
    src: local('SportingGrotesque-Bold'),
         url('../fonts/SportingGrotesque-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Clearface-Regular';
    src: local('Clearface-Regular'),
         url('../fonts/Clearface-Regular.woff') format('woff');
  
  }

  @font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
         url('../fonts/NeueMontreal-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Voyage';
    src: local('grandefoyage'),
         url('../fonts/grandefoyage.woff') format('woff');
}

.projects-section{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}


.projects-header{
    height: 15%;
    color: #ffffff;
    padding-left: 3rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: left;
    align-items: center;
    gap: 20px;
}


h2{
    font-size: 50px;
    font-family: 'Sporting-Bold';
}

p{
    font-size: 25px;
    font-family: 'NeueMontreal-Medium';
}

.carousel-container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 85%;
    overflow: hidden;
}

.carousel-inner{
    background: white;
    width: 97%;
    height: fit-content;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-left: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    transition: 0.8s cubic-bezier(0.5,-.3,.265,1);
}


.view-all{
    margin-top: 3rem;
    font-family: 'Sporting-Bold';
    font-size: 25px;
    text-decoration: none;
    width: fit-content ;


}


.carousel-inner a:hover{
    transition:  0.2s cubic-bezier(0.5,0.3,.265,1);
    filter: invert(57%) sepia(94%) saturate(150%) hue-rotate(322deg) brightness(100%) contrast(99%);
}

.underline{
    height: 4px;
    margin-top: -.5rem;
    background-color: #fc7a5b;
    transition: 0.8s cubic-bezier(0.5,-.3,.265,1);
}


@media only screen and (max-width: 1366px) {
    .carousel-inner{
        height: fit-content;
    }
}