@font-face {
    font-family: 'Clearface-Regular';
    src: local('Clearface-Regular'),
         url('../fonts/Clearface-Regular.woff') format('woff');
  
  }
@font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
         url('../fonts/NeueMontreal-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Sporting-Bold';
    src: local('SportingGrotesque-Bold'),
         url('../fonts/SportingGrotesque-Bold.woff') format('woff');
}

* {
    box-sizing: border-box;
    
}

.side-menu-content-3 {
    width: 90%;
    height: 100%;
    flex-direction: column;
    flex: none;
    order: 0;
    gap: 0;
    background-color: rgb(222, 222, 222);
    justify-content: space-between;
    align-self: auto;
    align-items: flex-start;
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-left: 3em;
    display: flex;
    --p: 100%;
    color: white;
    border-radius: 60px;
    transition: 1s cubic-bezier(0.5,-.3,.265,1);  
    overflow: hidden;
    padding-bottom: 0;
}

.side-menu-content-3-active{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    transition: 1s cubic-bezier(0.5,-.3,.265,1);  

}

.side-menu-content-3 h1{
    font-family: 'Sporting-Bold';
    font-size: 60px;
    line-height: 58px;
    margin: 0;
    margin-bottom: 3rem;
}
.side-menu-section-2 {
    z-index: 999;
    width: 800px;
    max-width: 800px;
    min-height: 100vh;
    background-color: #ffffff;
    padding-top: 0;
    display: flex;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;
    padding: 30px;
    transition:  0.8s cubic-bezier(0.5,0.3,.265,1);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.nav-hidden{
    transform: translate(-90%);
}

.side-menu{
    z-index: 99999;
    width: 10%;
    height: 100vh;
    z-index: 800;
    background-color: white;
    border-right-style: none;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;

}

.side-menu-click{
    display: flex;
    align-items: center;
    height: 70vh;
}

.active{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition:  0.5s 
}


.side-menu-wrapper{
    width: 100%;
}


.social-links{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social-links>a>img{
    width: 45px;
    height: 45px;
    filter: invert(57%) sepia(94%) saturate(1014%) hue-rotate(322deg) brightness(100%) contrast(99%);
    transition:  0.2s cubic-bezier(0.5,0.3,.265,1);
}
.social-links>a>img:hover{
    scale: 120%;
    transition:  0.2s cubic-bezier(0.5,0.3,.265,1);
    filter: invert(57%) sepia(94%) saturate(150%) hue-rotate(322deg) brightness(100%) contrast(99%);
}

p{
    text-align: left;
    font-family: 'Clearface-Regular';
    font-size: large;
    width: 90%;
}


.logo{
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo img{
    width: 80%;
}

.nav-links{
    width: 80%;
    border-radius: 60px;
    font-family: 'Sporting-Bold';
    color: #2b2b2b;
    font-size: 60px;
    text-decoration: none;
    line-height: 60px;
    margin-bottom: 0;
    transform: translate(-100%);
}

.animate{
    animation: .6s ease-out 0s 1 slideInFromLeft forwards;
}

.nav-links:hover{
    scale: 102%;
    transition:  0.2s;
    opacity:80%
}

.side-menu-content-3 img {
    width: 80%;
    margin-top: -3rem;

}

.nav-link-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    justify-content: left;
    color: #2b2b2b;
    margin-bottom: 5rem;
}

a:visited{
    text-decoration: none;
}

a{
    text-decoration: none;
}



@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

@media only screen and (max-width: 1366px) {
    .side-menu-section-2 {
        width: 600px;  
    }
    .nav-links{
        font-size: 55px;
    }
    .side-menu-content-3 img {
        margin-top: -3rem;
    
    }

}


