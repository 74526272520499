@font-face {
    font-family: 'Clearface-Regular';
    src: local('Clearface-Regular'),
         url('../fonts/Clearface-Regular.woff') format('woff');
  
  }
@font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
         url('../fonts/NeueMontreal-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Sporting-Bold';
    src: local('SportingGrotesque-Bold'),
         url('../fonts/SportingGrotesque-Bold.woff') format('woff');
}

*{
    /* border: 1px solid red; */
}

.hero-container{
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 90vw;
}


.left-column{
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    max-width: 700px;

}

.image-container{
    max-height: 50rem;
    width: 90%;
    height: 75%;
    border-bottom-right-radius: 50px;
    aspect-ratio: 4 / 5;
    display: block;
    overflow: hidden;
    
}

.image-container img{
    width: 100%;
    height: 100%;
    aspect-ratio: 4 / 5;
    object-fit: cover;
}

.hero-left-text{
    display: flex;
    align-content: center;

}

.hero-left-text>p{
    color: #ffffff;
    padding-left: 3rem;
    padding-top: 2rem;
    font-family: 'NeueMontreal-Medium';
    font-size: 25px;
    line-height: 28px;

}

.hero-left-text>p>span{
    color: #fc7a5b;
    font-family: 'NeueMontreal-Medium';
    font-size: 25px;
    line-height: 20px;
}

.right-column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0;
    max-height: 100vh;
}

.hero-title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1px;


}


.hero-title>h1{
    color: #ffffff;
    font-size: 130px;
    font-family: 'NeueMontreal-Medium';
    padding-left: 5%;
    margin-top: 0;
    margin-bottom: 0;
}

.hero-title>p{
    color:#ffffff;
    font-size: 25px;
    font-family: 'NeueMontreal-Medium';
    padding-left: 5%;
    margin-top: -20px;
    margin-bottom: 0;
    z-index: 1;
}

.hero-title-images{
    /* overflow: hidden; */
    width: 80%;
    height: 150px;
    z-index: 2;
    margin-top: -20px;
}

.hero-title-images>img{
    height:160%;
    margin-top: -70px;
    margin-left: -40px;
}

.contact-button{
    color: #fc7a5b;
    text-align: right;
    width: 100%;
    font-family: 'Sporting-Bold';
    font-size: 20px;
    width: fit-content;
    margin-left: 65%;
    text-decoration: none;
    font-size: 30px;
    transition: .3s;
    
}


.contact-button-main{
    text-decoration: none;
}
.contact-button:hover{
   color: white;
}

.contact-underline{
    height: 4px;
    margin-top: -.7rem;
    transition: .3s;
}



a:visited {
    color: #fc7a5b;
  }


.right-column>img{
    margin-left: auto;
    height: 18rem;
    width: 95%;
    margin-top: 6rem;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    object-fit: cover;

}

@media only screen and (max-width: 1366px) {

    /* HERO RIGHT COLUMN */
    .right-column{
        gap: 3rem;
    } 

    .hero-title>h1{
        font-size: 120px;   
    }
    .hero-title-images>img{
        height:130%;
        margin-top: -50px;
        margin-left: -20px;
    }
    .right-column>img{
        margin-top: 2rem;
        height: 15rem;
        
    }
    .hero-title>p{
        margin-top: -50px;
    }
    .contact-button{
        margin-left: 70%;
        font-size: 25px;
    }

    /* HERO LEFT COLUMN */
    .hero-left-text>p{
        font-size: 20px;
        line-height: 20px;
        padding-top: 1rem;
    }
    .hero-left-text>p>span{
        font-size: 20px;
        font-family: 'NeueMontreal-Medium';
    }
    }


/* Mobile */


@media only screen and (max-width: 400px) {

    /* HERO RIGHT COLUMN */
    .hero-container{
        max-width: 100vw;
    }
    }
